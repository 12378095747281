<template>
  <div class="pricing-new">
    <b-row class="align-self-stretch">
      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('PERCENTAGE_OFF')"
          @click="openModalPromotion('PERCENTAGE_OFF')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-percentage fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.PERCENTAGE_OFF.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.PERCENTAGE_OFF.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.PERCENTAGE_OFF.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('AMOUNT_OFF')"
          @click="openModalPromotion('AMOUNT_OFF')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-comments-dollar fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('DISCOUNT_FEE')"
          @click="openModalPromotion('DISCOUNT_FEE')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-tags fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.DISCOUNT_FEE.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.DISCOUNT_FEE.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.DISCOUNT_FEE.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('CUSTOM')"
          @click="openModalPromotion('CUSTOM')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-gift fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.CUSTOM.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.CUSTOM.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.CUSTOM.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('DURATION_EXTENSION')"
          @click="openModalPromotion('DURATION_EXTENSION')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-calendar-plus fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.DURATION_EXTENSION.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.DURATION_EXTENSION.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.DURATION_EXTENSION.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('DISCOUNT_OTHER_OFFERING')"
          @click="openModalPromotion('DISCOUNT_OTHER_OFFERING')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-cart-arrow-down fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.DISCOUNT_OTHER_OFFERING.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.DISCOUNT_OTHER_OFFERING.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.DISCOUNT_OTHER_OFFERING.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-card
          :class="checkIfExistPromotionType('AGENT_BONUS_COMMISSION')"
          @click="openModalPromotion('AGENT_BONUS_COMMISSION')"
        >
          <b-row>
            <b-col lg="1" md="1" sm="1">
              <br /><i class="fa fa-chart-line fa-2x"></i>
            </b-col>
            <b-col lg="11" md="11" sm="11">
              <h5>
                {{ $t("PROMOTIONS.TYPES.AGENT_BONUS_COMMISSION.TITLE") }}
              </h5>
              <div class="text-black-50">
                {{ $t("PROMOTIONS.TYPES.AGENT_BONUS_COMMISSION.TEXT") }}
              </div>
              <div class="text-black-50 font-weight-light font-italic">
                {{ $t("PROMOTIONS.TYPES.AGENT_BONUS_COMMISSION.SUBTEXT") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <PercentageOff ref="PercentageOff" />
    <AmountOff ref="AmountOff" />
    <DurationExtension ref="DurationExtension" />
    <CustomOffer ref="CustomOffer" />
    <AgentBonusCommission ref="AgentBonusCommission" />
    <DiscountOtherProducts ref="DiscountOtherProducts" />
    <OverridePriceTemplate ref="OverridePriceTemplate" />
    <DiscountFee ref="DiscountFee" />
  </div>
</template>

<script>
import PercentageOff from "@/view/pages/promotions/components/PercentageOff";
import AmountOff from "@/view/pages/promotions/components/AmountOff";
import DurationExtension from "@/view/pages/promotions/components/DurationExtension";
import CustomOffer from "@/view/pages/promotions/components/CustomOffer";
import AgentBonusCommission from "@/view/pages/promotions/components/AgentBonusCommission";
import DiscountOtherProducts from "@/view/pages/promotions/components/DiscountOtherProducts";
import OverridePriceTemplate from "@/view/pages/promotions/components/OverridePriceTemplate";
import DiscountFee from "@/view/pages/promotions/components/DiscountFee";
export default {
  props: {
    reloadPromotion: {
      type: Function,
    },
    types: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {};
  },
  components: {
    PercentageOff,
    AmountOff,
    DurationExtension,
    CustomOffer,
    AgentBonusCommission,
    DiscountOtherProducts,
    OverridePriceTemplate,
    DiscountFee,
  },
  methods: {
    checkIfExistPromotionType(promotionType) {
      if (this.types.indexOf(promotionType) > -1) {
        return "option-cards3";
      } else {
        return "option-cards2";
      }
    },
    openModalPromotion(type) {
      if (type === "PERCENTAGE_OFF") {
        this.$refs.PercentageOff.dialog = true;
      }
      if (type === "AMOUNT_OFF") {
        this.$refs.AmountOff.dialog = true;
      }
      if (type === "DURATION_EXTENSION") {
        this.$refs.DurationExtension.dialog = true;
      }
      if (type === "CUSTOM") {
        this.$refs.CustomOffer.dialog = true;
      }
      if (type === "AGENT_BONUS_COMMISSION") {
        this.$refs.AgentBonusCommission.dialog = true;
      }
      if (type === "DISCOUNT_OTHER_OFFERING") {
        this.$refs.DiscountOtherProducts.dialog = true;
      }
      if (type === "OVERRIDE_PRICE_TEMPLATE") {
        this.$refs.OverridePriceTemplate.dialog = true;
      }
      if (type === "DISCOUNT_FEE") {
        this.$refs.DiscountFee.dialog = true;
      }
    },
  },
};
</script>
