<template>
  <div>
    <v-main>
      <v-row gutters="2" class="pt-0 pb-0" justify="end">
        <v-col cols="12" md="12" class="text-right">
          <Buttons
            :text-cancel="$t('GENERAL.CANCEL')"
            @cancel="cancel"
            :text-save="$t('GENERAL.SAVE')"
            @save="save"
            :btn-duplicate="true"
            @duplicate="duplicate"
            :text-duplicate="$t('GENERAL.DUPLICATE')"
            :btn-delete="true"
            :text-delete="$t('GENERAL.DELETE')"
            @delete="deleteRecordModal"
          />
        </v-col>
      </v-row>
      <validation-observer ref="observer">
        <v-row gutters="2" class="pt-0 pb-0">
          <v-col cols="12" md="8" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PROMOTIONS.FORM.PROMOTION_INFORMATION")
              }}</v-card-title>
              <v-card-text>
                <!-- Row Field Name -->
                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('PROMOTIONS.FORM.NAME')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="form.name"
                        dense
                        outlined
                        :error-messages="errors"
                        :label="$t('PROMOTIONS.FORM.NAME')"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <!-- Row Field Name -->
                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('PROMOTIONS.FORM.DESCRIPTION')"
                    >
                      <v-textarea
                        v-model="form.description"
                        dense
                        outlined
                        :error-messages="errors"
                        :label="$t('PROMOTIONS.FORM.DESCRIPTION')"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PROMOTIONS.FORM.ELEGIBLE_PRODUCTS") }}
              </v-card-title>
              <v-card-text v-if="courses.length > 0">
                <v-tabs fixed-tabs>
                  <v-tab>{{ $t("PROMOTIONS.FORM.COURSES") }}</v-tab>
                  <v-tab>{{ $t("PROMOTIONS.FORM.ACCOMMODATION") }}</v-tab>
                  <v-tab>{{ $t("PROMOTIONS.FORM.SERVICES") }} </v-tab>

                  <v-tab-item style="padding-top: 10px;">
                    <ProductsTreeView
                      :input.sync="form.courses"
                      :selection="form.courses"
                      :data="courses"
                      @change="joinEligibleProducts"
                    />
                  </v-tab-item>
                  <v-tab-item style="padding-top: 10px;">
                    <ProductsTreeView
                      :input.sync="form.accommodations"
                      :selection="form.accommodations"
                      :data="rooms"
                      key-name="room_with_lodging"
                      @change="joinEligibleProducts"
                    />
                  </v-tab-item>
                  <v-tab-item style="padding-top: 10px;">
                    <ProductsTreeView
                      :input.sync="form.services"
                      :selection="form.services"
                      :data="addons"
                      @change="joinEligibleProducts"
                    />
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PROMOTIONS.FORM.PROMOTION_DETAILS") }}
              </v-card-title>
              <v-card-text>
                <div>
                  <TypesGrid
                    v-if="typeof this.promotion.promotionId !== 'undefined'"
                    :reloadPromotion="reloadPromotion"
                    :key="componentKey"
                    :types="promotion.types"
                    ref="TypesGrid"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="12">
                    <v-switch
                      v-model="form.isReady"
                      :label="`${$t('PROMOTIONS.PUBLISH')}`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PROMOTIONS.MARKETS_COUNTRY_SELECTOR_TITLE") }}
              </v-card-title>
              <v-card-text>
                <MarketsCountriesSelector
                  :markets-countries-data="marketsCountriesSelected"
                  :input.sync="form.marketsCountriesSelected"
                  is-required
                ></MarketsCountriesSelector>
              </v-card-text>
            </v-card>

            <v-card class="mb-2">
              <v-card-title>{{
                $t("PROMOTIONS.VALID_TIME_FRAME")
              }}</v-card-title>
              <v-card-text>
                <p class="subtitle-1">
                  {{ $t("PROMOTIONS.VALID_FOR_BOOKINGS_MADE") }}...
                </p>

                <v-row align="baseline" class="mt-0 mb-0 pt-0 pb-0">
                  <v-col cols="12" md="6" class="mt-0 mb-0 pt-0 pb-0">
                    <DatePicker
                      :date="form.beginBookingExpiry"
                      :is-required="true"
                      :name="$t('GENERAL.FROM')"
                      :name-large="
                        `${$t('GENERAL.FROM')} ${$t('PROMOTIONS.BOOKINGS')}`
                      "
                      :input.sync="form.beginBookingExpiry"
                    />
                    <v-checkbox
                      style="margin-top: -15px;"
                      v-model="form.validBooking"
                      :label="$t('GENERAL.NEVER_EXPIRES')"
                      @change="form.endBookingExpiry = form.beginBookingExpiry"
                    />
                  </v-col>
                  <v-col
                    v-if="!form.validBooking"
                    cols="12"
                    md="6"
                    class="mt-0 mb-0 pt-0 pb-0"
                  >
                    <DatePicker
                      :date="form.endBookingExpiry"
                      :is-required="true"
                      :date-min="form.beginBookingExpiry"
                      :name="$t('GENERAL.TO')"
                      :input.sync="form.endBookingExpiry"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text>
                <p class="subtitle-1">
                  {{ $t("PROMOTIONS.VALID_FOR_START_DATES") }}...
                </p>

                <v-row align="baseline" class="mt-0 mb-0 pt-0 pb-0">
                  <v-col cols="12" md="6" class="mt-0 mb-0 pt-0 pb-0">
                    <DatePicker
                      :date="form.beginStartDateExpiry"
                      :is-required="true"
                      :name="$t('GENERAL.FROM')"
                      :name-large="
                        `${$t('GENERAL.FROM')} ${$t('PROMOTIONS.START_DATES')}`
                      "
                      :input.sync="form.beginStartDateExpiry"
                    />
                    <v-checkbox
                      style="margin-top: -15px;"
                      v-model="form.validStartDate"
                      :label="$t('GENERAL.NEVER_EXPIRES')"
                      @change="
                        form.endStartDateExpiry = form.beginStartDateExpiry
                      "
                    />
                  </v-col>
                  <v-col
                    v-if="!form.validStartDate"
                    cols="12"
                    md="6"
                    class="mt-0 mb-0 pt-0 pb-0"
                  >
                    <DatePicker
                      :date="form.endStartDateExpiry"
                      :is-required="true"
                      :date-min="form.beginStartDateExpiry"
                      :name="$t('GENERAL.TO')"
                      :input.sync="form.endStartDateExpiry"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </validation-observer>
    </v-main>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deleteRecord">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import TypesGrid from "@/view/pages/promotions/components/TypesGrid";
import { mapActions, mapGetters } from "vuex";
import MarketsCountriesSelector from "@/view/content/components/MarketsCountriesSelector";
import { DELETE_PROMOTION } from "@/core/services/store/general/promotion.module";
import Buttons from "@/view/content/components/Buttons";
import DatePicker from "@/view/content/components/DatePicker";

export default {
  name: "promotions-edit",
  components: {
    ValidationProvider,
    ValidationObserver,
    ProductsTreeView,
    TypesGrid,
    MarketsCountriesSelector,
    Buttons,
    DatePicker,
  },
  data() {
    return {
      errors: [],
      isShowDialogDelete: false,
      componentKey: 0,
      marketsCountriesSelected: {},
      form: {
        promotionId: this.$route.params.id,
        isReady: false,
        isDuplicate: false,
        typesTotal: 0,
        name: null,
        description: null,
        courses: [],
        accommodations: [],
        services: [],
        marketsCountriesSelected: {},
        beginBookingExpiry: null,
        endBookingExpiry: null,
        beginStartDateExpiry: null,
        endStartDateExpiry: null,
        validBooking: true,
        validStartDate: true,
        eligibleProducts: "",
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PROMOTIONS.TITLE"), route: "/promotions" },
      { title: this.$t("PROMOTIONS.EDIT_PROMOTION") },
    ]);

    this.getCourses();
    this.getAddons();
    this.getRooms();

    await this.loadData();
    await this.reloadPromotion();
  },
  methods: {
    ...mapActions([
      "getCourses",
      "getAddons",
      "getRooms",
      "getPromotion",
      "updatePromotion",
    ]),
    loadData: async function() {
      await this.getPromotion(this.$route.params.id);

      if (typeof this.promotion.promotionId === "undefined") {
        await this.$router.push({
          name: "promotions",
        });
      }

      if (this.promotion.isEligibleAllCountries === 1) {
        this.marketsCountriesSelected = { isEligibleAllCountries: 1 };
      } else if (this.promotion.eligibleCountries) {
        this.marketsCountriesSelected = {
          eligibleCountries: this.promotion.eligibleCountries,
        };
      } else if (this.promotion.eligibleMarkets) {
        this.marketsCountriesSelected = {
          eligibleMarkets: this.promotion.eligibleMarkets,
        };
      } else {
        this.marketsCountriesSelected = {};
      }

      this.form.typesTotal = this.promotion.typesTotal;
      this.form.isReady = this.promotion.isReady;
      this.form.name = this.promotion.name;
      this.form.description = this.promotion.description;
      this.form.courses = this.promotion.courses;
      this.form.services = this.promotion.services;
      this.form.accommodations = this.promotion.accommodations;
      this.form.marketsCountriesSelected = this.marketsCountriesSelected;
      this.form.beginBookingExpiry = this.promotion.beginBookingExpiry;
      this.form.endBookingExpiry = this.promotion.endBookingExpiry;
      this.form.beginStartDateExpiry = this.promotion.beginStartDateExpiry;
      this.form.endStartDateExpiry = this.promotion.endStartDateExpiry;
      this.form.validStartDate = this.promotion.validStartDate;
      this.form.validBooking = this.promotion.validBooking;
    },
    reloadPromotion: async function() {
      //await this.getPromotion(this.$route.params.id);
      //await this.loadData();
      this.componentKey += 1;
    },
    async duplicate() {
      this.form.isDuplicate = true;
      await this.save();
    },
    async save() {
      if (await this.$refs.observer.validate()) {
        this.form.eligibleProducts = this.joinEligibleProducts();
        const response = await this.updatePromotion(this.form);

        if (this.form.isDuplicate) {
          this.form.isDuplicate = false;
          this.form.promotionId = response.promotionId;
          await this.$router.push({
            name: "promotions-edit",
            params: { id: response.promotionId },
          });
        } else {
          await this.$bvToast.toast(this.$t("GENERAL.SAVED_SUCCESS"), {
            title: "Success",
            variant: "success",
            solid: true,
          });
        }
      } else {
        this.form.isDuplicate = false;
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    joinEligibleProducts() {
      const eligibleProducts = [];

      for (let i = 0; i < this.form.courses.length; i++) {
        eligibleProducts.push(this.form.courses[i]["id"]);
      }

      for (let i = 0; i < this.form.accommodations.length; i++) {
        eligibleProducts.push(this.form.accommodations[i]["id"]);
      }

      for (let i = 0; i < this.form.services.length; i++) {
        eligibleProducts.push(this.form.services[i]["id"]);
      }

      return eligibleProducts.join(",");
    },
    formatDate(date) {
      if (this.$moment(date, "YYYY-MM-DD").isValid()) {
        return this.$moment(date, "YYYY-MM-DD").format(this.campus.date_format);
      }
    },
    async deleteRecord() {
      const response = await this.$store.dispatch(DELETE_PROMOTION, this.form);
      if (response) {
        await this.$bvToast.toast(this.$t("GENERAL.DELETED_SUCCESS"), {
          title: "Success",
          variant: "success",
          solid: true,
        });

        await this.$router.push({
          name: "promotions",
        });
      }
    },
    deleteRecordModal() {
      this.isShowDialogDelete = true;
    },
    async cancel() {
      await this.$router.push({
        name: "promotions",
      });
    },
  },
  computed: {
    ...mapGetters(["courses", "rooms", "addons", "promotion", "campus"]),
  },
  watch: {
    $route: "loadData",
  },
};
</script>
