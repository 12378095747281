<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="promotions-component">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("PROMOTIONS.TYPES.OVERRIDE_PRICE_TEMPLATE.TITLE") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark class="h6" text @click="save">
          {{ $t("GENERAL.SAVE") }}
        </v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t("PROMOTIONS.FORM.PROMOTION_DETAILS") }}
        <v-row align="center" justify="end">
          <a class="body-2" v-if="help === false" @click="help = true">
            {{ $t("GENERAL.SHOW_HELP") }}
          </a>
          <a class="body-2" v-else @click="help = false">
            {{ $t("GENERAL.HIDE_HELP") }}
          </a>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-alert
          v-model="help"
          type="info"
          border="left"
          elevation="2"
          colored-border
        >
          {{ $t("PROMOTIONS.TYPES.OVERRIDE_PRICE_TEMPLATE.HELP_TEXT1") }}
        </v-alert>

        <b-row>
          <b-col lg="8" md="10" sm="12">
            <PricingTemplate
              :productPricingTemplateId="productPriceTemplateId"
              :availableOptions="[1, 2, 3]"
              ref="pricingTemplate"
              :key="componentKey"
            />
          </b-col>
        </b-row>
        <b-row v-if="promotionTypeSelectionId > 0">
          <b-col lg="8" md="10" sm="12">
            <a
              @click="isShowDialogDelete = true"
              class="btn btn-danger btn-hover-light"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <v-icon color="white">
                  mdi-delete
                </v-icon>
              </span>
              {{ $t("PROMOTIONS.TYPES.OVERRIDE_PRICE_TEMPLATE.DELETE_BTN") }}
            </a>
          </b-col>
        </b-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deleteRecord">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
import { DELETE_PRODUCT_PRICING } from "@/core/services/store/configure/product_pricing.module";
export default {
  components: {
    PricingTemplate,
  },
  data() {
    return {
      promotionTypeId: 7,
      form: [],
      dialog: false,
      help: true,
      componentKey: 0,
      isShowDialogDelete: false,
    };
  },
  created() {
    this.componentKey += 1;
  },
  computed: {
    ...mapGetters(["promotion", "productPricing"]),
    promotions: function() {
      const promotionDetails = this.promotion.promotionTypeSelections?.filter(
        promotion => promotion.promotionTypeId === this.promotionTypeId
      );

      if (promotionDetails.length > 0) {
        return promotionDetails[0];
      } else {
        return [];
      }
    },
    promotionTypeSelectionId: function() {
      return typeof this.promotions.promotionTypeSelectionId !== "undefined"
        ? this.promotions.promotionTypeSelectionId
        : 0;
    },
    productPriceTemplateId: function() {
      return typeof this.promotions.productPriceTemplateId !== "undefined"
        ? this.promotions.productPriceTemplateId
        : 0;
    },
  },
  watch: {
    dialog: function(value) {
      if (value === false) this.$parent.reloadPromotion();
    },
  },
  methods: {
    ...mapActions(["storePromotionDetail"]),
    closeModal() {
      this.dialog = false;
    },
    async save() {
      this.$refs.pricingTemplate.validateAndGetData().then(async data => {
        this.form = {
          promotionTypeSelectionId: this.promotionTypeSelectionId,
          promotionId: this.promotion.promotionId,
          promotionTypeId: this.promotionTypeId,
          promotionSecondaryTypeId: 0,
          amountIsPerDuration: 0,
          productPriceTemplateId: this.productPriceTemplateId,
          discountedProducts: null,
          promotionDetails: [],
        };

        if (data) {
          if (data.productPricingTemplateId === 0) {
            await this.$refs.pricingTemplate.store(data);
            this.form.productPriceTemplateId = this.productPricing.productPricingTemplateId;
          } else {
            await this.$refs.pricingTemplate.save(data);
          }

          await this.storePromotionDetail(this.form);
        }
      });
    },
    async deleteRecord() {
      await this.$store
        .dispatch(
          DELETE_PRODUCT_PRICING,
          this.productPricing.productPricingTemplateId
        )
        .then(() => {
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });

      this.form = {
        promotionTypeSelectionId: this.promotionTypeSelectionId,
        promotionId: this.promotion.promotionId,
        promotionTypeId: this.promotionTypeId,
        promotionSecondaryTypeId: 0,
        amountIsPerDuration: 0,
        productPriceTemplateId: 0,
        discountedProducts: null,
        promotionDetails: [],
        promotionFeeDetails: [],
        deletePromotionType: true,
      };

      await this.storePromotionDetail(this.form);
      this.isShowDialogDelete = false;
      this.componentKey += 1;
    },
  },
};
</script>
