<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="promotions-component">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("PROMOTIONS.TYPES.AGENT_BONUS_COMMISSION.TITLE") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark class="h6" text @click="save">
          {{ $t("GENERAL.SAVE") }}
        </v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t("PROMOTIONS.FORM.PROMOTION_DETAILS") }}
        <v-row align="center" justify="end">
          <a class="body-2" v-if="help === false" @click="help = true">
            {{ $t("GENERAL.SHOW_HELP") }}
          </a>
          <a class="body-2" v-else @click="help = false">
            {{ $t("GENERAL.HIDE_HELP") }}
          </a>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-alert
          v-model="help"
          type="info"
          border="left"
          elevation="2"
          colored-border
        >
          <strong>{{ $t("PROMOTIONS.INCREASE_COMMISION_RATE_BY") }} %:</strong>
          {{ $t("PROMOTIONS.TYPES.CUSTOM.HELP_TEXT1") }}
          <br />
          <strong>{{ $t("PROMOTIONS.DOLLAR_AMOUNT_BONUS") }}:</strong>
          {{ $t("PROMOTIONS.TYPES.CUSTOM.HELP_TEXT2") }}
          <br />
          <strong>$ {{ $t("PROMOTIONS.OVERRIDE_COMMISSION_RATE") }}:</strong>
          {{ $t("PROMOTIONS.TYPES.CUSTOM.HELP_TEXT3") }}
        </v-alert>

        <b-row>
          <b-col lg="8" md="10" sm="12">
            <div v-if="promotionSecondaryTypeIdSelected === 2">
              <validation-observer ref="observer">
                <table class="table-content" style="width: 100%;">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ $t("GENERAL.BONUS") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <draggable tag="tbody" :list="item" handle=".handle">
                    <tr
                      v-for="(row, index) in item"
                      :key="index"
                      class="mb-3 border-row"
                    >
                      <td class="move-list">
                        <i class="fa fa-bars handle"></i>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('GENERAL.BONUS')"
                          :rules="{
                            required: true,
                            min_value: 1,
                            max_value: 100,
                          }"
                        >
                          <v-text-field
                            v-model="item[index].amount"
                            outlined
                            dense
                            type="number"
                            :error-messages="errors"
                            append-icon="mdi-percent"
                          />
                          <div class="v-messages theme--light error--text mt-1">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <a
                          class="btn btn-icon btn-light btn-hover-danger"
                          @click="deleteRow(index)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <v-icon color="red">
                              mdi-delete
                            </v-icon>
                          </span>
                        </a>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </validation-observer>
            </div>

            <div v-if="promotionSecondaryTypeIdSelected === 3">
              <validation-observer ref="observer">
                <table class="table-content" style="width: 100%;">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ $t("GENERAL.COMMISSION") }}</th>
                      <th colspan="2">
                        {{ $t("PROMOTIONS.MIN_STUDY_DURATION") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="blue-grey lighten-3"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <div>
                            <div
                              style="width: 200px; line-height: 14px;"
                              class="body-2"
                            >
                              {{
                                $t("PROMOTIONS.TYPES.PERCENTAGE_OFF.HELP_TEXT3")
                              }}
                            </div>
                          </div>
                        </v-tooltip>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <draggable tag="tbody" :list="item" handle=".handle">
                    <tr
                      v-for="(row, index) in item"
                      :key="index"
                      class="mb-3 border-row"
                    >
                      <td class="move-list">
                        <i class="fa fa-bars handle"></i>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('GENERAL.COMMISSION')"
                          :rules="{
                            required: true,
                            min_value: 1,
                            max_value: 100,
                          }"
                        >
                          <v-text-field
                            v-model="item[index].amount"
                            outlined
                            dense
                            type="number"
                            :error-messages="errors"
                            append-icon="mdi-percent"
                          />
                          <div class="v-messages theme--light error--text mt-1">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </td>
                      <td class="input-unit">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('GENERAL.DURATION')"
                          :rules="{
                            required: true,
                            min_value: 1,
                          }"
                        >
                          <v-text-field
                            v-model="item[index].duration"
                            outlined
                            dense
                            type="number"
                            :error-messages="errors"
                          ></v-text-field>
                          <div class="v-messages theme--light error--text mt-1">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('GENERAL.DURATION_TYPE')"
                          :rules="{
                            required: true,
                          }"
                        >
                          <v-select
                            v-model="item[index].durationTypeId"
                            outlined
                            dense
                            item-value="id"
                            :label="$t('GENERAL.DURATION_TYPE')"
                            :items="durationTypes"
                            :error-messages="errors"
                          ></v-select>
                          <div class="v-messages theme--light error--text mt-1">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <a
                          class="btn btn-icon btn-light btn-hover-danger"
                          @click="deleteRow(index)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <v-icon color="red">
                              mdi-delete
                            </v-icon>
                          </span>
                        </a>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-btn depressed color="primary" @click="addItem">
              <i class="fa fa-plus"></i>
              {{ $t("PROMOTIONS.FORM.ADD_TIER") }}
            </v-btn>
          </v-col>
        </v-row>

        <b-row>
          <b-col lg="8" md="10" sm="12" :class="productsHasError">
            <v-tabs fixed-tabs>
              <v-tab>{{ $t("PROMOTIONS.FORM.COURSES") }}</v-tab>
              <v-tab>{{ $t("PROMOTIONS.FORM.ACCOMMODATION") }}</v-tab>
              <v-tab>{{ $t("PROMOTIONS.FORM.SERVICES") }} </v-tab>

              <v-tab-item style="padding-top: 10px;">
                <ProductsTreeView
                  :input.sync="eligibleProductsSelected.courses"
                  :selection="eligibleProductsSelected.courses"
                  :data="courses"
                />
              </v-tab-item>
              <v-tab-item style="padding-top: 10px;">
                <ProductsTreeView
                  :input.sync="eligibleProductsSelected.accommodations"
                  :selection="eligibleProductsSelected.accommodations"
                  :data="rooms"
                />
              </v-tab-item>
              <v-tab-item style="padding-top: 10px;">
                <ProductsTreeView
                  :input.sync="eligibleProductsSelected.services"
                  :selection="eligibleProductsSelected.services"
                  :data="addons"
                />
              </v-tab-item>
            </v-tabs>
          </b-col>
          <b-col lg="8" md="10" sm="12" v-if="productsHasError.length > 0">
            <span style="color: red;">
              {{ $t("PROMOTIONS.ERROR_SELECT_PRODUCTS") }}
            </span>
          </b-col>
        </b-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import draggable from "vuedraggable";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import { DEFAULT_DURATION_TYPE } from "@/core/services/utils/durationTypesFormat";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    draggable,
    ProductsTreeView,
  },
  data() {
    return {
      promotionTypeId: 6,
      form: [],
      eligibleProductsSelected: {
        courses: [],
        accommodations: [],
        services: [],
      },
      dialog: false,
      help: true,
      item: [],
      promotionSecondaryTypeIdSelected: 2,
      amountIsPerDurationSelected: 0,
      productsHasError: "",
      durationTypes: [
        {
          id: 3,
          text: this.$t("GENERAL.WEEKS"),
        },
        {
          id: 4,
          text: this.$t("GENERAL.MONTHS"),
        },
      ],
      amountIsPerDurationTypes: [
        {
          id: 0,
          text: this.$t("PROMOTIONS.PER_REGISTRATION"),
        },
        {
          id: 1,
          text: this.$t("PROMOTIONS.PER_UNIT_2"),
        },
      ],
      promotionSecondaryTypes: [
        {
          id: 1,
          text: this.$t("PROMOTIONS.BONUS_AMOUNT"),
        },
        {
          id: 2,
          text: this.$t("PROMOTIONS.BONUS_PERCENTAGE"),
        },
        {
          id: 3,
          text: this.$t("PROMOTIONS.COMMISSION_OVERRIDE"),
        },
      ],
    };
  },
  async mounted() {
    await this.loadItems();
  },
  computed: {
    ...mapGetters([
      "courses",
      "rooms",
      "addons",
      "promotion",
      "eligibleProducts",
    ]),
    promotions: function() {
      const promotionDetails = this.promotion.promotionTypeSelections?.filter(
        promotion => promotion.promotionTypeId === this.promotionTypeId
      );

      if (promotionDetails.length > 0) {
        return promotionDetails[0];
      } else {
        return [];
      }
    },
    promotionTypeSelectionId: function() {
      return typeof this.promotions.promotionTypeSelectionId !== "undefined"
        ? this.promotions.promotionTypeSelectionId
        : 0;
    },
    amountIsPerDuration: function() {
      return typeof this.promotions.amountIsPerDuration !== "undefined"
        ? this.promotions.amountIsPerDuration
        : 0;
    },
    promotionSecondaryTypeId: function() {
      return typeof this.promotions.promotionSecondaryTypeId !== "undefined"
        ? this.promotions.promotionSecondaryTypeId
        : 1;
    },
    computedEligibleProducts: function() {
      return typeof this.promotions.discountedProducts !== "undefined"
        ? this.promotions.discountedProducts
        : "";
    },
  },
  watch: {
    dialog: function(value) {
      if (value === false) this.$parent.reloadPromotion();
    },
  },
  methods: {
    ...mapActions(["storePromotionDetail", "getEligibleProducts"]),
    closeModal() {
      this.dialog = false;
    },
    newItem() {
      this.item.push({
        amount: null,
        duration: null,
        durationTypeId: DEFAULT_DURATION_TYPE,
      });
    },
    async addItem() {
      if (await this.$refs.observer.validate()) {
        this.newItem();
      }
    },
    async loadItems() {
      this.promotionSecondaryTypeIdSelected = 2;
      this.amountIsPerDurationSelected = this.amountIsPerDuration;

      await this.promotions.promotionDetails?.map(promotion => {
        this.item.push({
          amount: this.calcAmount(parseFloat(promotion.amount), 1),
          duration: promotion.minDuration,
          durationTypeId: promotion.durationTypeId,
        });
      });

      await this.loadEligibleProducts(this.computedEligibleProducts);

      if (this.item.length === 0) await this.newItem();
    },
    async loadEligibleProducts(ids) {
      if (ids !== "") {
        await this.getEligibleProducts(this.promotions.discountedProducts);
        this.eligibleProductsSelected.courses = this.eligibleProducts.courses;
        this.eligibleProductsSelected.accommodations = this.eligibleProducts.accommodations;
        this.eligibleProductsSelected.services = this.eligibleProducts.services;
      }
    },
    cleanItems() {
      this.item = [];
      this.newItem();
    },
    calcAmount(amount, operator) {
      if (operator === 1) {
        if (this.promotionSecondaryTypeIdSelected === 2) {
          return amount * 100;
        } else if (this.promotionSecondaryTypeIdSelected === 3) {
          return amount * 100;
        } else {
          return amount;
        }
      } else {
        if (this.promotionSecondaryTypeIdSelected === 2) {
          return amount / 100;
        } else if (this.promotionSecondaryTypeIdSelected === 3) {
          return amount / 100;
        } else {
          return amount;
        }
      }
    },
    async save() {
      //this.$parent.reloadPromotion();
      if (await this.$refs.observer.validate()) {
        if (
          this.eligibleProductsSelected.courses.length > 0 ||
          this.eligibleProductsSelected.accommodations.length > 0 ||
          this.eligibleProductsSelected.services.length > 0
        ) {
          this.productsHasError = "";
          this.form = {
            promotionTypeSelectionId: this.promotionTypeSelectionId,
            promotionId: this.promotion.promotionId,
            promotionTypeId: this.promotionTypeId,
            promotionSecondaryTypeId: this.promotionSecondaryTypeIdSelected,
            amountIsPerDuration: this.amountIsPerDurationSelected,
            productPriceTemplateId: 0,
            discountedProducts: this.eligibleProductsSelected,
            promotionDetails: [],
            promotionFeeDetails: [],
          };

          await this.item?.map(itemPromo => {
            this.form.promotionDetails.push({
              promotionTypeSelectionId: this.promotionTypeSelectionId,
              amount: this.calcAmount(parseFloat(itemPromo.amount), 2),
              minDuration: itemPromo.duration,
              durationTypeId: itemPromo.durationTypeId,
              description: 0,
            });
          });

          await this.storePromotionDetail(this.form);

          await this.$bvToast.toast(this.$t("GENERAL.SAVED_SUCCESS"), {
            title: "Success",
            variant: "success",
            solid: true,
          });
        } else {
          this.productsHasError = "hasError";
          this.$bvToast.toast(this.$t("PROMOTIONS.ERROR_SELECT_PRODUCTS"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    deleteRow(index) {
      this.item.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.hasError {
  transition: border 0.1s linear;
  margin-top: 3px;
  border: 2px solid red;
  border-radius: 5px;
}
</style>
