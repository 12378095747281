<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="promotions-component">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.TITLE") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark class="h6" text @click="save">
          {{ $t("GENERAL.SAVE") }}
        </v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t("PROMOTIONS.FORM.PROMOTION_DETAILS") }}
        <v-row align="center" justify="end">
          <a class="body-2" v-if="help === false" @click="help = true">
            {{ $t("GENERAL.SHOW_HELP") }}
          </a>
          <a class="body-2" v-else @click="help = false">
            {{ $t("GENERAL.HIDE_HELP") }}
          </a>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-alert
          v-model="help"
          type="info"
          border="left"
          elevation="2"
          colored-border
        >
          <strong>{{ $t("PROMOTIONS.PER_REGISTRATION") }}:</strong>
          {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.HELP_TEXT4") }}
          <br />
          <strong>{{ $t("PROMOTIONS.PER_UNIT") }}:</strong>
          {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.HELP_TEXT5") }}
          <br />
          <br />
          <strong>{{ $t("PROMOTIONS.FEE_AMOUNT_OFF") }}:</strong>
          {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.HELP_TEXT1") }}
          <br />
          <strong>{{ $t("PROMOTIONS.MIN_STUDY_DURATION") }}</strong>
          {{ $t("PROMOTIONS.TYPES.AMOUNT_OFF.HELP_TEXT2") }}
        </v-alert>

        <b-row>
          <b-col lg="8" md="10" sm="12">
            <validation-observer ref="observer">
              <b-row>
                <b-col lg="4" md="4" sm="12">
                  <v-select
                    v-model="amountIsPerDurationSelected"
                    outlined
                    dense
                    item-value="id"
                    :items="amountIsPerDurationTypes"
                  />
                </b-col>
              </b-row>

              <table class="table-content" style="width: 100%;">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $t("PROMOTIONS.AMOUNT_OFF") }}</th>
                    <th colspan="2">
                      {{ $t("PROMOTIONS.MIN_STUDY_DURATION") }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="blue-grey lighten-3"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <div>
                          <div
                            style="width: 200px; line-height: 14px;"
                            class="body-2"
                          >
                            {{
                              $t("PROMOTIONS.TYPES.PERCENTAGE_OFF.HELP_TEXT3")
                            }}
                          </div>
                        </div>
                      </v-tooltip>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <draggable tag="tbody" :list="item" handle=".handle">
                  <tr
                    v-for="(row, index) in item"
                    :key="index"
                    class="mb-3 border-row"
                  >
                    <td class="move-list">
                      <i class="fa fa-bars handle"></i>
                    </td>
                    <td>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('GENERAL.AMOUNT')"
                        :rules="{
                          required: true,
                          min_value: 1,
                        }"
                      >
                        <v-text-field
                          v-model="item[index].amount"
                          outlined
                          dense
                          type="number"
                          :error-messages="errors"
                          prepend-inner-icon="mdi-currency-usd"
                        />
                        <div class="v-messages theme--light error--text mt-1">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </td>
                    <td class="input-unit">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('GENERAL.DURATION')"
                        :rules="{
                          required: true,
                          min_value: 1,
                        }"
                      >
                        <v-text-field
                          v-model="item[index].duration"
                          outlined
                          dense
                          type="number"
                          :error-messages="errors"
                        ></v-text-field>
                        <div class="v-messages theme--light error--text mt-1">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('GENERAL.DURATION_TYPE')"
                        :rules="{
                          required: true,
                        }"
                      >
                        <v-select
                          v-model="item[index].durationTypeId"
                          outlined
                          dense
                          item-value="id"
                          :label="$t('GENERAL.DURATION_TYPE')"
                          :items="durationTypes"
                          :error-messages="errors"
                        ></v-select>
                        <div class="v-messages theme--light error--text mt-1">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </td>
                    <td class="text-center">
                      <a
                        class="btn btn-icon btn-light btn-hover-danger"
                        @click="deleteRow(index)"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-danger">
                          <v-icon color="red">
                            mdi-delete
                          </v-icon>
                        </span>
                      </a>
                    </td>
                  </tr>
                </draggable>
              </table>
            </validation-observer>
          </b-col>
        </b-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-btn depressed color="primary" @click="addItem">
              <i class="fa fa-plus"></i>
              {{ $t("PROMOTIONS.FORM.ADD_TIER") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import draggable from "vuedraggable";
import { DEFAULT_DURATION_TYPE } from "@/core/services/utils/durationTypesFormat";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    draggable,
  },
  data() {
    return {
      promotionTypeId: 1,
      amountIsPerDurationSelected: 0,
      form: [],
      dialog: false,
      help: true,
      item: [],
      durationTypes: [
        {
          id: 3,
          text: this.$t("GENERAL.WEEKS"),
        },
        {
          id: 4,
          text: this.$t("GENERAL.MONTHS"),
        },
      ],
      amountIsPerDurationTypes: [
        {
          id: 0,
          text: this.$t("PROMOTIONS.PER_REGISTRATION"),
        },
        {
          id: 1,
          text: this.$t("PROMOTIONS.PER_UNIT_2"),
        },
      ],
    };
  },
  async mounted() {
    await this.loadItems();
  },
  computed: {
    ...mapGetters(["promotion"]),
    promotions: function() {
      const promotionDetails = this.promotion.promotionTypeSelections?.filter(
        promotion => promotion.promotionTypeId === this.promotionTypeId
      );

      if (promotionDetails.length > 0) {
        return promotionDetails[0];
      } else {
        return [];
      }
    },
    promotionTypeSelectionId: function() {
      return typeof this.promotions.promotionTypeSelectionId !== "undefined"
        ? this.promotions.promotionTypeSelectionId
        : 0;
    },
    amountIsPerDuration: function() {
      return typeof this.promotions.amountIsPerDuration !== "undefined"
        ? this.promotions.amountIsPerDuration
        : 0;
    },
  },
  watch: {
    dialog: function(value) {
      if (value === false) this.$parent.reloadPromotion();
    },
  },
  methods: {
    ...mapActions(["storePromotionDetail"]),
    closeModal() {
      this.dialog = false;
    },
    newItem() {
      this.item.push({
        amount: null,
        duration: null,
        durationTypeId: DEFAULT_DURATION_TYPE,
      });
    },
    async addItem() {
      if (await this.$refs.observer.validate()) {
        this.newItem();
      }
    },
    async loadItems() {
      this.amountIsPerDurationSelected = this.amountIsPerDuration;

      await this.promotions.promotionDetails?.map(promotion => {
        this.item.push({
          amount: promotion.amount * 100,
          duration: promotion.minDuration,
          durationTypeId: promotion.durationTypeId,
        });
      });

      if (this.item.length === 0) await this.newItem();
    },
    async save() {
      //this.$parent.reloadPromotion();
      if (await this.$refs.observer.validate()) {
        this.form = {
          promotionTypeSelectionId: this.promotionTypeSelectionId,
          promotionId: this.promotion.promotionId,
          promotionTypeId: this.promotionTypeId,
          promotionSecondaryTypeId: 0,
          amountIsPerDuration: this.amountIsPerDurationSelected,
          productPriceTemplateId: 0,
          discountedProducts: null,
          promotionDetails: [],
          promotionFeeDetails: [],
        };

        await this.item?.map(itemPromo => {
          this.form.promotionDetails.push({
            promotionTypeSelectionId: this.promotionTypeSelectionId,
            amount: parseInt(itemPromo.amount) / 100,
            minDuration: itemPromo.duration,
            durationTypeId: itemPromo.durationTypeId,
            description: 0,
          });
        });

        await this.storePromotionDetail(this.form);

        await this.$bvToast.toast(this.$t("GENERAL.SAVED_SUCCESS"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    deleteRow(index) {
      this.item.splice(index, 1);
    },
  },
};
</script>
